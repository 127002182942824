<template>
	<div style="display: contents;">
		<v-alert
			v-bind="$attrs"
			v-show="!selectedUtility"
			colored-border border="left" elevation="3"
			:type="messageType"
			:icon="messageIcon"
			:class="alertClass"
		>{{ message }}</v-alert>
		<div style="display: contents;" v-show="selectedUtility">
			<slot />
		</div>
	</div>
</template>

<script>
import SelectedUtilityMixin from '@/ittijs/SelectedUtilityMixin';
export default {
	props: {
		message: {
			type: String,
			default: "Моля, изберете дружество!",
		},
		messageType: {
			type: String,
			default: "warning",
		},
		messageIcon: {
			type: String,
			default: "mdi-alert-outline",
		},
		alertClass: {},
	},
	mixins: [SelectedUtilityMixin],
	name: "PleaseSelectUtility"
}
</script>