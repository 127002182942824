<template>
	<v-container fluid>
		<please-select-utility>

			<v-row dense>
				<v-col>
					<v-toolbar class="headings" dense rounded>
						<div>Аб. Станция:</div>
						<div>
							<station-select-all
								v-model="stationId"
								mesechni
								clearable solo hide-details dense flat
								class="flex-grow-1 ml-4"
							/>
						</div>
					</v-toolbar>
				</v-col>
			</v-row>

			<v-row dense v-if="!stationId">
				<v-col>
					<v-alert dense type="info">
						Ако не е избрана станция, ще бъдат принтирани всички активни станции на месечен отчет в дружеството!
					</v-alert>
				</v-col>
			</v-row>

			<v-row dense>
				<v-col>
					<v-form ref="form" class="d-flex">
						<v-sheet elevation="2" rounded class="mr-2 pb-4">
							<v-toolbar color="headings" flat dense class="rounded-t">
								<v-icon class="mr-2">mdi-wifi</v-icon>
								Радио уреди
							</v-toolbar>
							<base-input required type="radiogroup" v-model="showRadioDevices" :options="[
								{value:'yes',text:'Да'},
								{value:'no',text:'Не'},
							]" class="mx-2" />
						</v-sheet>
						<v-sheet elevation="2" rounded class="mr-2 pb-4">
							<v-toolbar color="headings" flat dense class="rounded-t">
								<v-icon class="mr-2">mdi-weather-partly-cloudy</v-icon>
								Сезон
							</v-toolbar>
							<base-input required type="radiogroup" v-model="season" :options="[
								{value:'summer',text:'Летен (май-септември)'},
								{value:'winter',text:'Зимен (октомври-април)'},
							]" class="mx-2" />
						</v-sheet>
						<v-sheet elevation="2" rounded class="mr-2 pb-4">
							<v-toolbar color="headings" flat dense class="rounded-t">
								<v-icon class="mr-2">mdi-sort-alphabetical-ascending</v-icon>
								Сортиране на имотите
							</v-toolbar>
							<base-input required type="radiogroup" v-model="sortOrder" :options="[
								{value:'imot_N',text:'По абонатен номер'},
								{value:'location',text:'По локация'},
							]" class="mx-2" />
						</v-sheet>
					</v-form>
				</v-col>
			</v-row>

			<v-row dense>
				<v-col>
					<v-btn color="primary" @click="onClickPrint">
						<v-icon left>mdi-printer</v-icon>
						Принтирай
					</v-btn>
				</v-col>
			</v-row>

		</please-select-utility>
	</v-container>
</template>

<script>
import PleaseSelectUtility from "@/ittijs/components/PleaseSelectUtility";
import StationSelectAll from "@/views/stations/StationSelectAll";
import BaseInput from "@/ittijs/Inputs/BaseInput";
import Model from "./Model";

const model = new Model;

export default {
	components: {StationSelectAll, PleaseSelectUtility, BaseInput},
	props: [
		'utility',
	],
	data(){
		return {
			stationId: null,
			showRadioDevices: null,
			season: null,
			sortOrder: null,
		}
	},
	methods: {
		onClickPrint(){
			if (!this.$refs.form.validate()) {
				return;
			}
			const url = model.getMesechniPrintURL(
				this.utility,
				this.stationId,
				this.showRadioDevices,
				this.season,
				this.sortOrder
			);
			window.open(url, '_blank');
		},
	},
}
</script>