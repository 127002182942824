var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('please-select-utility',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-alert',{attrs:{"dense":"","type":"info"}},[_vm._v(" Сваляне на ZIP файл, съдържащ по един CSV файл за всяка станция, предназначен за печатница. ")])],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('download-btn-with-progress',{attrs:{"rest-service":_vm.downloadGodishni,"button-props":{
						color: 'primary',
						small: false,
						class: 'mr-2',
					}},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" Станции на годишен отчет ")]},proxy:true},{key:"confirm-content",fn:function(){return [_vm._v(" За по-големите дружества това може да отнеме няколко минути ")]},proxy:true}])}),_c('download-btn-with-progress',{attrs:{"rest-service":_vm.downloadMesechni,"button-props":{
						color: 'primary',
						small: false,
						class: 'mr-2',
					}},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" Станции на месечен отчет ")]},proxy:true},{key:"confirm-content",fn:function(){return [_vm._v(" За по-големите дружества това може да отнеме няколко минути ")]},proxy:true}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }