import ITTIModel from '../../ittijs/ITTIModel';

export default class extends ITTIModel {
	constructor() {
		super('Stations');
	}
	async getSelectOptions(params){
		return await this.fetch('getSelectOptions', params);
	}
	async getSelectOptionsAll(params){
		return await this.fetch('getSelectOptionsAll', params);
	}
	async getLatestSessionInfo(station){
		return await this.fetch('getLatestSessionInfo', {station});
	}
	getLastSessionState(station){
		return this.fetch('getLastSessionState', {station});
	}
	lastSessionAdd(station){
		return this.fetch('lastSessionAdd', {station}, {});
	}
	lastSessionRemove(station){
		return this.fetch('lastSessionRemove', {station}, {});
	}
	importImoti(station, file, progressCallback){
		return this.uploadWithProgress(
			'importImoti',
			{station},
			{files:file},
			null,
			progressCallback
		);
	}
}

export class StationFrontDesk extends ITTIModel {
	constructor() {
		super('StationFrontDesk');
	}
}
