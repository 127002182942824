var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('please-select-utility',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-toolbar',{staticClass:"headings",attrs:{"dense":"","rounded":""}},[_c('div',[_vm._v("Аб. Станция:")]),_c('div',[_c('station-select-all',{staticClass:"flex-grow-1 ml-4",attrs:{"mesechni":"","clearable":"","solo":"","hide-details":"","dense":"","flat":""},model:{value:(_vm.stationId),callback:function ($$v) {_vm.stationId=$$v},expression:"stationId"}})],1)])],1)],1),(!_vm.stationId)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-alert',{attrs:{"dense":"","type":"info"}},[_vm._v(" Ако не е избрана станция, ще бъдат принтирани всички активни станции на месечен отчет в дружеството! ")])],1)],1):_vm._e(),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-form',{ref:"form",staticClass:"d-flex"},[_c('v-sheet',{staticClass:"mr-2 pb-4",attrs:{"elevation":"2","rounded":""}},[_c('v-toolbar',{staticClass:"rounded-t",attrs:{"color":"headings","flat":"","dense":""}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-wifi")]),_vm._v(" Радио уреди ")],1),_c('base-input',{staticClass:"mx-2",attrs:{"required":"","type":"radiogroup","options":[
							{value:'yes',text:'Да'},
							{value:'no',text:'Не'},
						]},model:{value:(_vm.showRadioDevices),callback:function ($$v) {_vm.showRadioDevices=$$v},expression:"showRadioDevices"}})],1),_c('v-sheet',{staticClass:"mr-2 pb-4",attrs:{"elevation":"2","rounded":""}},[_c('v-toolbar',{staticClass:"rounded-t",attrs:{"color":"headings","flat":"","dense":""}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-weather-partly-cloudy")]),_vm._v(" Сезон ")],1),_c('base-input',{staticClass:"mx-2",attrs:{"required":"","type":"radiogroup","options":[
							{value:'summer',text:'Летен (май-септември)'},
							{value:'winter',text:'Зимен (октомври-април)'},
						]},model:{value:(_vm.season),callback:function ($$v) {_vm.season=$$v},expression:"season"}})],1),_c('v-sheet',{staticClass:"mr-2 pb-4",attrs:{"elevation":"2","rounded":""}},[_c('v-toolbar',{staticClass:"rounded-t",attrs:{"color":"headings","flat":"","dense":""}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-sort-alphabetical-ascending")]),_vm._v(" Сортиране на имотите ")],1),_c('base-input',{staticClass:"mx-2",attrs:{"required":"","type":"radiogroup","options":[
							{value:'imot_N',text:'По абонатен номер'},
							{value:'location',text:'По локация'},
						]},model:{value:(_vm.sortOrder),callback:function ($$v) {_vm.sortOrder=$$v},expression:"sortOrder"}})],1)],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onClickPrint}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-printer")]),_vm._v(" Принтирай ")],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }