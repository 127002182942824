<template>
	<div>
		<v-tabs v-model="tabs">
			<v-tab>Месечен отчет</v-tab>
			<v-tab>Годишен отчет</v-tab>
			<v-tab>Брой лица</v-tab>
		</v-tabs>
		<v-tabs-items v-model="tabs">
			<v-tab-item><mesechni :utility="utility" /></v-tab-item>
			<v-tab-item><godishni :utility="utility" /></v-tab-item>
			<v-tab-item><broi-lica :utility="utility" /></v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import Mesechni from "./Mesechni";
import Godishni from "./Godishni";
import BroiLica from "./BroiLica";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import RouteSyncMixin from "@/ittijs/RouteSyncMixin";

export default {
	components: {
		BroiLica,
		Mesechni,
		Godishni,
	},
	mixins: [
		SelectedUtilityMixin,
		RouteSyncMixin({
			utility: 'selectedUtility',
		}),
	],
	props: [
		'utility',
	],
	data(){
		return {
			tabs: null,
		}
	},
}
</script>