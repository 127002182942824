<template>
	<v-container fluid>
		<please-select-utility>

			<v-row dense>
				<v-col>
					<v-alert dense type="info">
						Сваляне на ZIP файл, съдържащ по един CSV файл за всяка станция,
						предназначен за печатница.
					</v-alert>
				</v-col>
			</v-row>

			<v-row dense>
				<v-col>

					<download-btn-with-progress
						:rest-service="downloadGodishni"
						:button-props="{
							color: 'primary',
							small: false,
							class: 'mr-2',
						}"
					>
						<template #button-content>
							<v-icon left>mdi-download</v-icon>
							Станции на годишен отчет
						</template>
						<template #confirm-content>
							За по-големите дружества това може да отнеме няколко минути
						</template>
					</download-btn-with-progress>

					<download-btn-with-progress
						:rest-service="downloadMesechni"
						:button-props="{
							color: 'primary',
							small: false,
							class: 'mr-2',
						}"
					>
						<template #button-content>
							<v-icon left>mdi-download</v-icon>
							Станции на месечен отчет
						</template>
						<template #confirm-content>
							За по-големите дружества това може да отнеме няколко минути
						</template>
					</download-btn-with-progress>

				</v-col>
			</v-row>

		</please-select-utility>
	</v-container>
</template>

<script>
import PleaseSelectUtility from "@/ittijs/components/PleaseSelectUtility";
import Model from "./Model";
import DownloadBtnWithProgress
	from "@/ittijs/components/DownloadBtnWithProgress";

const model = new Model;

export default {
	components: {DownloadBtnWithProgress, PleaseSelectUtility},
	props: [
		'utility',
	],
	data(){
		return {
		}
	},
	methods: {
		downloadGodishni(){
			return model.downloadGodishenOtchetGodishni(this.utility);
		},
		downloadMesechni(){
			return model.downloadGodishenOtchetMesechni(this.utility);
		},
	},
}
</script>