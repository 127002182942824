import {debounce} from 'lodash';

/**
 * Синхронизира 3 неща:
 * 1. route params
 * 2. component props
 * 3. component data items
 *
 * При промяна на (1) рутера се грижи да синхронизира (2). При промяна на (2)
 * ние трябва да синхронизираме (3). (3) може да бъде обвързано с контрола чрез
 * v-model, така че при промяна ние трябва да причиним URL навигация с
 * router.push(), което затваря кръга на синхронизация обратно до (1).
 *
 * За да се направи каскадна зависимост, към кое трабва да се закачим в
 * компонента? (2) или (3)?
 */

export default (map)=>{
	const watch = {};

	/**
	 * Collect route params here while debouncing, and then clear
	 */
	let routeParams = {};

	const debounceRoute = debounce(function(router, params){
		router.push({params});
		routeParams = {};
	}, 1);

	for (const routeProp in map) {
		const componentDataItem = map[routeProp];
		watch[componentDataItem] = {
			// immediate: true, // if enabled, this wipes the route immediately on mount
			handler: function(val){
				if (val != this.$route.params[routeProp]) {
					routeParams = { ...this.$route.params, ...routeParams, [routeProp]: val };
					debounceRoute(this.$router, routeParams);
				}
			},
		};
		watch[routeProp] = {
			immediate: true,
			handler: function(val){
				if (val != this[componentDataItem]) {
					this[componentDataItem] = val;
				}
			},
		};
	}
	return {
		watch,
	};
}