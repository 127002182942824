<template>
	<v-container fluid>
		<please-select-utility>

			<v-row dense>
				<v-col>
					<v-alert dense type="info">
						Сваляне на ZIP файл, съдържащ по един XLS файл за всяка станция,
						шаблон за домоуправителя за попълване на брой лица в имотите.
					</v-alert>
				</v-col>
			</v-row>

			<v-row dense>
				<v-col>
					<download-btn-with-progress
						:rest-service="onClickDownload"
						:button-props="{
							color: 'primary',
							small: false,
						}"
					>
						<template #button-content>
							<v-icon left>mdi-download</v-icon>
							Свали
						</template>
					</download-btn-with-progress>
				</v-col>
			</v-row>

		</please-select-utility>
	</v-container>
</template>

<script>
import PleaseSelectUtility from "@/ittijs/components/PleaseSelectUtility";
import Model from "./Model";
import DownloadBtnWithProgress
	from "@/ittijs/components/DownloadBtnWithProgress";

const model = new Model;

export default {
	components: {DownloadBtnWithProgress, PleaseSelectUtility},
	props: [
		'utility',
	],
	data(){
		return {
		}
	},
	methods: {
		onClickDownload(){
			return model.downloadBroiLica(this.utility);
		},
	},
}
</script>