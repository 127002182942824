<template>
	<v-autocomplete
		v-bind="$attrs"
		:value="value"
		@input="$emit('input', $event)"
		:items="items"
		hide-no-data
		:loading="loading"
		item-text="address"
		item-value="id"
		:filter="search"
	>
		<template v-slot:selection="data">
			<span class="grey--text" style="white-space: pre-wrap;">{{data.item.station_N}}: </span>{{ data.item.address }}
			{{ data.item.building_N > 0 ? '(' + data.item.building_N + ')' : '' }}
			<v-chip small v-if="data.item.mesechen=='1'" class="mx-1">месечен</v-chip>
			<v-chip small v-else-if="data.item.mesechen=='0'" class="mx-1">годишен</v-chip>
			<v-chip small v-else-if="session && data.item.active=='0'" class="mx-1" color="error lighten-2">неакт.</v-chip>
		</template>
		<template v-slot:item="data">
			<span class="grey--text" style="white-space: pre-wrap;">{{data.item.station_N}}: </span>{{ data.item.address }}
			{{ data.item.building_N > 0 ? '(' + data.item.building_N + ')' : '' }}
			<v-spacer/>
			<v-chip small v-if="data.item.mesechen=='1'" class="mx-1" dark>месечен</v-chip>
			<v-chip small v-else-if="data.item.mesechen=='0'" class="mx-1" light>годишен</v-chip>
			<v-chip small v-else-if="session && data.item.active=='0'" class="mx-1" color="error lighten-2">неакт.</v-chip>
		</template>
	</v-autocomplete>
</template>

<script>
import SelectedUtilityMixin from '@/ittijs/SelectedUtilityMixin';
import { itemsForSelect } from "@/ittijs/utils";
import ModelStations from "./Model";

const modelStations = new ModelStations();

const TRANSLIT = {
	'sht': 'щ',
	'sh': 'ш',
	'ts': 'ц',
	'tz': 'ц',
	'ch': 'ч',
	'yu': 'ю',
	'ya': 'я',
	'zh': 'ж',
	'a': 'а',
	'b': 'б',
	'v': 'в',
	'g': 'г',
	'd': 'д',
	'e': 'е',
	'z': 'з',
	'i': 'и',
	'y': 'й',
	'k': 'к',
	'l': 'л',
	'm': 'м',
	'n': 'н',
	'o': 'о',
	'p': 'п',
	'r': 'р',
	's': 'с',
	't': 'т',
	'u': 'у',
	'f': 'ф',
	'h': 'х',
	'j': 'ж',
};

const doTranslit = function(string){
	const res = [string];
	for (const k in TRANSLIT) {
		const v = TRANSLIT[k];
		string = string.replaceAll(k, v);
	}
	if (string !== res[0]) res.push(string);
	return res;
}

export default {

	mixins: [
		SelectedUtilityMixin,
	],

	components: {},

	props: {
		value: {},
		session: {},
		mesechni: Boolean, // todo
		godishni: Boolean, // todo
	},

	data(){
		return {
			items: [],
			loading: false,
			searchTerm: null,
			searchTermPrepared: null,
		}
	},

	computed: {
		selectedItem(){
			return this.items.find(e => e.value == this.value);
		},
		itemsSorted(){
			return [...this.items].sort((a,b) => parseInt(a.station_N) - parseInt(b.station_N));
		},
	},

	watch: {
		selectedUtility: {
			immediate: true,
			handler(){
				this.load();
			},
		},
		session: {
			immediate: true,
			handler(){
				this.load();
			},
		},
	},

	methods: {
		search(item, queryText){
			let arrQuery;
			if (this.searchTerm === queryText && Array.isArray(this.searchTermPrepared)) {
				arrQuery = this.searchTermPrepared;
			}
			else {
				arrQuery = queryText
					.split(' ')
					.map(s=>s.trim().toLowerCase())
					.map(doTranslit)
				;
				this.searchTerm = queryText;
				this.searchTermPrepared = arrQuery;
			}

			return arrQuery.every(chunks => chunks.some(s => {
				if (item.station_N.toLowerCase().includes(s)) return true;
				if (item.address.toLowerCase().includes(s)) return true;
				return false;
			}));
		},
		async load(){
			if (!this.selectedUtility) return;
			this.loading = true;
			this.items = itemsForSelect(
				await modelStations.getSelectOptionsAll({
					'utility_code' : this.selectedUtility,
					'session'      : this.session,
				})
			);
			this.loading = false;
		},
	},

}
</script>