import {ITTIModelBase} from "@/ittijs/ITTIModel";
export default class extends ITTIModelBase {
	constructor() {
		super('OtchetniListove');
	}
	getMesechniPrintURL(utility, station, showRadioDevices, season, sortOrder) {
		return this.getRequestURL('getMesechniPrintHTML', {
			utility, station, showRadioDevices, season, sortOrder,
		});
	}
	downloadGodishenOtchetGodishni(utility) {
		return this.fetch('downloadGodishenOtchetGodishni', {utility});
	}
	downloadGodishenOtchetMesechni(utility) {
		return this.fetch('downloadGodishenOtchetMesechni', {utility});
	}
	downloadBroiLica(utility) {
		return this.fetch('downloadBroiLica', {utility});
	}
}